<template>
    <div class="chart-wrapper">
        <div class="l-padded datepicker">
            <DateRangeInput v-model="customRange" />
        </div>

        <div class="chart">
            <div v-if="isFilterVisisble" class="l-stack l-gap-1 filter">
                <BaseMultiselect
                    v-model="filterAssets"
                    :placeholder="$t('shared.assets')"
                    :options="trackers"
                    track-by="id"
                    :custom-label="option => option.asset_details.name"
                    multiple
                />

                <BaseMultiselect
                    v-model="filterLocations"
                    :placeholder="$t('locations')"
                    :options="locationsSortedByName"
                    track-by="id"
                    label="name"
                    multiple
                />

                <BaseButton
                    size="small"
                    @click="isAssetsMainAxis = !isAssetsMainAxis"
                >
                    {{ $t('switchAxes') }}
                </BaseButton>
            </div>

            <div v-if="isLoading" class="l-stack l-center l-padded">
                <VSpinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <ApexChart
                v-else-if="series.length"
                :height="chartHeight"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { formatHelper, httpHelper } from '@/utils'
import BaseButton from '@/components/redesigned/BaseButton'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect'
import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'
import UrlHelper from '@/mixins/UrlHelper'

const colors = {
    [true]: '#41b883',
    [false]: '#00a1de',
    [null]: '#aaa',
}

export default {
    name: 'LocationHistoryChartView',
    components: {
        ApexChart,
        BaseButton,
        BaseMultiselect,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper, UrlHelper],
    props: {
        fullHeight: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            responseData: [],
            isFilterVisisble: false,
            isAssetsMainAxis: true,
            isAssetView: false,
            filterAssets: [],
            filterLocations: [],
            series: [],
            chartOptions: {
                chart: {
                    type: 'rangeBar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: ([start, end]) =>
                        formatHelper.hoursAndMinutesDuration(
                            moment.duration(end - start).asSeconds()
                        ),
                },
                tooltip: {
                    x: {
                        format: 'dd.MM.yyyy HH:mm:ss',
                    },
                },
                xaxis: {
                    type: 'datetime',
                },
                yaxis: {
                    showForNullSeries: false,
                    labels: {
                        formatter: value => {
                            if (this.isAssetView) {
                                const durations = this.series.map(
                                    ({ data }) => {
                                        const duration = data
                                            .filter(({ x }) => x === value)
                                            .reduce(
                                                (time, { y: [start, end] }) => {
                                                    return time + end - start
                                                },
                                                0
                                            )

                                        if (duration) {
                                            return `(${formatHelper.hoursAndMinutesDuration(
                                                duration / 1000
                                            )})`
                                        }
                                    }
                                )

                                return [value, ...durations.filter(Boolean)]
                            }

                            const duration = this.series.reduce(
                                (total, asset) => {
                                    const current = asset.data
                                        .filter(({ x }) => x === value)
                                        .reduce((time, { y: [start, end] }) => {
                                            return time + end - start
                                        }, 0)

                                    return total + current
                                },
                                0
                            )

                            return duration
                                ? [
                                      value,
                                      `(${formatHelper.hoursAndMinutesDuration(
                                          duration / 1000
                                      )})`,
                                  ]
                                : value
                        },
                    },
                },
                legend: {
                    showForSingleSeries: true,
                    showForNullSeries: false,
                    showForZeroSeries: false,
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    computed: {
        ...mapState('tracker', ['assetTypes', 'trackers']),
        ...mapGetters('location', ['locationsSortedByName']),
        chartHeight() {
            return this.fullHeight
                ? document.documentElement.clientHeight - 320
                : 400
        },
    },
    watch: {
        customRange() {
            this.load()
        },
        filterAssets() {
            this.load()
        },
        filterLocations() {
            this.load()
        },
        isAssetsMainAxis() {
            this.renderData()
        },
    },
    created() {
        moment.locale(this.$i18n.locale)
    },
    mounted() {
        if (this.$route.name === 'assetLocationHistoryChart') {
            const id = +this.$route.params.id
            this.filterAssets = [this.trackers.find(item => item.id === id)]
            this.chartOptions.colors = [
                ({ seriesIndex }) => colors[this.series[seriesIndex].running],
            ]
            this.isAssetView = true
        } else if (this.$route.name === 'locationHistoryChart') {
            const id = +this.$route.params.id
            this.filterLocations = [
                this.locationsSortedByName.find(item => item.id === id),
            ]
            this.isAssetsMainAxis = false
        } else {
            this.isFilterVisisble = true
        }
        this.keepAsQueryParams(true, {
            'customRange.startDate': {
                key: 'start',
                type: 'date',
            },
            'customRange.endDate': {
                key: 'end',
                type: 'date',
            },
        })
    },
    methods: {
        load: debounce(async function() {
            this.isLoading = true
            await this.loadData()
            this.isLoading = false
        }),
        async loadData() {
            const filterQueryParams =
                this.filterAssets
                    .map(asset => `&asset=${asset.asset}`)
                    .join('') +
                this.filterLocations
                    .map(location => `&location=${location.id}`)
                    .join('')

            if (!filterQueryParams) {
                this.series = []
                return
            }

            let results = []
            let url =
                'location-history/?' +
                `start=${encodeURIComponent(
                    moment(this.customRange.startDate).format()
                )}` +
                `&end=${encodeURIComponent(
                    moment(this.customRange.endDate).format()
                )}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}` +
                filterQueryParams

            while (url) {
                const { data } = await httpHelper.get(url)
                results = results.concat(data.results)
                url = data.next
            }

            this.responseData = results
            this.renderData()
        },
        renderData() {
            const [x, y] = this.isAssetView
                ? ['running', 'location']
                : this.isAssetsMainAxis
                ? ['asset', 'location']
                : ['location', 'asset']
            const [getXName, getYName] = this.isAssetView
                ? [this.getRunningState, this.getLocationName]
                : this.isAssetsMainAxis
                ? [this.getAssetName, this.getLocationName]
                : [this.getLocationName, this.getAssetName]

            this.series = this.responseData.reduce((acc, cur) => {
                if (cur.start === cur.end) {
                    return acc
                }

                const bucket = acc.find(item => item[x] === cur[x])
                const dataItem = {
                    x: getYName(cur[y]),
                    y: [
                        new Date(cur.start).getTime(),
                        new Date(cur.end).getTime(),
                    ],
                    ...(this.isAssetView && {
                        fillColor: colors[cur.running],
                    }),
                }

                if (bucket) {
                    bucket.data.push(dataItem)
                } else {
                    acc.push({
                        [x]: cur[x],
                        name: getXName(cur[x]),
                        data: [dataItem],
                    })
                }

                return acc
            }, [])
        },
        getAssetName(assetId) {
            return this.trackers.find(item => item.asset === assetId)
                ?.asset_details?.name
        },
        getRunningState(isRunning) {
            return typeof isRunning !== 'boolean'
                ? this.$t('unknown')
                : isRunning
                ? this.$t('running')
                : this.$t('notRunning')
        },
        getLocationName(locationId) {
            return this.locationsSortedByName.find(
                item => item.id === locationId
            )?.name
        },
    },
}
</script>

<i18n>
{
    "en": {
        "locations": "Locations",
        "nHours": "{n} hour | {n} hours",
        "notRunning": "Not running",
        "running": "Running",
        "switchAxes": "Switch axes",
        "unknown": "Unknown"
    },
    "de": {
        "locations": "Standorte",
        "nHours": "{n} Stunde | {n} Stunden",
        "notRunning": "Ausser Betrieb",
        "running": "In Betrieb",
        "switchAxes": "Achsen tauschen",
        "unknown": "Unbekannt"
    },
    "fr": {
        "locations": "Emplacements",
        "nHours": "{n} heure | {n} heures",
        "notRunning": "Hors fonctionnement",
        "running": "En fonctionnement",
        "switchAxes": "Changer d'axes",
        "unknown": "Inconnu"
    },
    "it": {
        "locations": "Locatione",
        "nHours": "{n} ora | {n} ore",
        "notRunning": "Fuori uso",
        "running": "In uso",
        "switchAxes": "Scambiare gli assi",
        "unknown": "Sconosciuto"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-wrapper {
    display: flex;
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
}

.filter {
    margin-left: 1rem;
    button {
        max-width: 120px;
    }
}

.datepicker {
    width: 20%;
    min-width: 350px;
}

@include respond-to('for-tablet-down') {
    .chart-wrapper {
        display: block;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }

    .filter {
        margin-bottom: 1rem;
        margin-left: 1rem;
    }

    .chart {
        border: none;
    }
}
</style>
